<template>
  <!-- 排班管理下的弹窗合集组件 -->
  <div class="workforcePopup" @click="boxClickFun($event)">
    <div
      class="manual-content"
      :style="{
        height:
          popupNum == 2
            ? '270px'
            : popupNum == 3
            ? '600px'
            : popupNum == 4
            ? '300px'
            : popupNum == 5
            ? '250px'
            : popupNum == 6
            ? '810px'
            : '200px',
        width: [3, 6].includes(popupNum) ? '800px' : '600px',
      }"
    >
      <div class="header">
        <div class="text">
          {{
            popupNum == 2
              ? "定时计划"
              : popupNum == 3
              ? "生成排班"
              : popupNum == 4
              ? "改班"
              : popupNum == 5
              ? "撤班"
              : popupNum == 6
              ? "预留"
              : ""
          }}
        </div>
        <div class="close" @click="closeFun()"></div>
      </div>
      <!-- 定时计划 -->
      <div v-if="popupNum == 2" class="content-2">
        <div class="row">
          <div class="row-left">线路</div>
          <div class="row-right">
            <el-select
              v-model="ScheduleValue"
              :disabled="disabled"
              :multiple="multiple"
              placeholder="请选择"
            >
              <el-option
                v-for="item in ScheduleLineSelect"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
        </div>
        <div class="row">
          <div class="row-left">每天</div>
          <div class="row-right">
            <el-time-picker
              v-model="departureTime"
              :disabled="disabled"
              format="HH:mm"
              value-format="HH:mm"
              placeholder="选择发车时间"
            ></el-time-picker>
            <span>生成</span>
            <el-select
              v-model="dayValue"
              :disabled="disabled"
              placeholder="请选择"
              class="content-2-input"
            >
              <el-option
                v-for="item in daySelect"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
            <span>天</span>
          </div>
        </div>
        <div class="row">
          <div class="row-left"></div>
          <div class="row-right">
            <el-checkbox v-model="SameCoverageChecked" :disabled="disabled"
              >覆盖相同班次</el-checkbox
            >
            <el-checkbox v-model="takeEffectChecked" :disabled="disabled"
              >计划生效</el-checkbox
            >
          </div>
        </div>
      </div>
      <!-- 生成排班 -->
      <div v-if="popupNum == 3" class="content-3">
        <div class="row">
          <div class="row-left">线路：</div>
          <div class="row-right">
            <el-select
              v-model="lineValue"
              placeholder="请输入搜索选择"
              clearable
              filterable
              @change="lineChange"
            >
              <el-option
                v-for="items in lineList"
                :key="items.id"
                :label="items.name"
                :value="items.id"
              ></el-option>
            </el-select>
          </div>
        </div>
        <div class="row">
          <div class="row-left">日期：</div>
          <div class="row-right">
            <el-date-picker
              id="pickers"
              v-model="generationTimeValue"
              type="daterange"
              value-format="yyyy-MM-dd"
              :picker-options="pickerOptions"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            ></el-date-picker>
          </div>
        </div>
        <div class="row">
          <div class="row-left">开班属性：</div>
          <div class="row-right">
            <el-checkbox-group v-model="shiftcheckList" @change="checkedChange">
              <el-checkbox
                v-for="item in shiftList"
                :key="item.id"
                :label="item.label"
              ></el-checkbox>
            </el-checkbox-group>
          </div>
        </div>
        <div class="row">
          <div class="row-left">班次：</div>
          <div class="row-right">
            <Table
              :table-data="tableData"
              :title-name="titleName"
              :table-height="300"
              :type-checkbox="true"
              :valuationManage="true"
              @select-all="handleSelectionChange"
            ></Table>
          </div>
        </div>
      </div>
      <!-- 改班 -->
      <div v-if="popupNum == 4" class="content-4">
        <div class="row">
          <div class="row-left">发车时间</div>
          <div class="row-right">
            <el-time-picker
              v-model="valueTime"
              format="HH:mm"
              value-format="HH:mm"
              placeholder="选择发车时间"
            ></el-time-picker>
          </div>
        </div>
        <div class="row">
          <div class="row-left">备注</div>
          <div class="row-right">
            <el-input v-model="textareaValue" type="textarea"></el-input>
          </div>
        </div>
      </div>
      <!-- 撤班 -->
      <div v-if="popupNum == 5" class="content-5">
        <div class="row">
          <div class="row-left">撤班原因</div>
          <div class="row-right">
            <el-input v-model="textareaValue" type="textarea"></el-input>
          </div>
        </div>
      </div>
      <!-- 预留 -->
      <div v-if="popupNum == 6" class="content-6">
        <el-form
          ref="reserveForm"
          label-width="100px"
          :model="reserve"
          :rules="reserveRules"
          class="reserveForm"
        >
          <el-row style="padding: 20px">
            <h4>预留班次信息</h4>
            <el-col :span="12">
              <el-form-item label="出发地：" prop="startStation">
                <el-select
                  v-if="startStation && reserveEditable"
                  v-model="reserve.startStation"
                >
                  <el-option
                    v-for="(item, index) in startStation"
                    :key="index"
                    :value="item.label"
                    :label="item.label"
                  ></el-option>
                </el-select>
                <el-input
                  v-else
                  placeholder="出发地"
                  disabled
                  v-model="reserve.startStation"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="到达地：" prop="endStation">
                <el-select
                  v-if="endStation && reserveEditable"
                  v-model="reserve.endStation"
                >
                  <el-option
                    v-for="(item, index) in endStation"
                    :key="index"
                    :value="item.label"
                    :label="item.label"
                  ></el-option>
                </el-select>
                <el-input
                  v-else
                  disabled
                  placeholder="到达地"
                  v-model="reserve.endStation"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="出行日期：" prop="departureDate">
                <el-date-picker
                  v-model="reserve.departureDate"
                  :disabled="!reserveEditable"
                  placeholder="出行日期"
                  value-format="yyyy-MM-dd"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="班次时间：" prop="shiftIntercityId">
                <!-- <el-input placeholder="班次时间"></el-input> -->
                <el-select
                  v-if="reserveEditable"
                  v-model="reserve.shiftIntercityId"
                  :loading="Times_loading"
                  placeholder="请选择班次"
                  @focus="searchWorkForceTimes"
                  @change="selectShift"
                >
                  <el-option
                    v-for="(item, index) in workForceTimes"
                    :key="index"
                    :value="item.id"
                    :label="item.label"
                  ></el-option>
                </el-select>
                <el-input v-else v-model="time_ticket" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="上车点：">
                <el-input
                  v-model="reserve.startAddress"
                  :readonly="true"
                  placeholder="请选择上车点"
                  style="width: 88%"
                />
                <i class="iconfont its_gy_map" @click="selectAddress(0)"></i>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="下车点：">
                <el-input
                  v-model="reserve.endAddress"
                  :readonly="true"
                  placeholder="请选择下车点"
                  style="width: 88%"
                />
                <i class="iconfont its_gy_map" @click="selectAddress(1)"></i>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="预留数量：" prop="reservedTicket">
                <el-input-number
                  :min="0"
                  :max="reserveSurplusTicket"
                  v-model="reserve.reservedTicket"
                  @change="changeReservedTicket"
                ></el-input-number>
              </el-form-item>
              <p v-if="showTip" class="sign">
                若要继续减少预留数量，请先减少携童数
              </p>
            </el-col>
            <el-col :span="12">
              <el-form-item label="携童数：" style="margin-bottom: 0">
                <el-input-number
                  :min="0"
                  :max="
                    children >= reserve.reservedTicket
                      ? reserve.reservedTicket
                      : children
                  "
                  v-model="reserve.carryingChildrenNum"
                  @change="changeReservedTicket"
                ></el-input-number>
                <span style="color: gray; margin-left: 100px">
                  此班次剩余携童票
                  <span style="color: red">{{ children }}</span> 张
                </span>
              </el-form-item>
            </el-col>
            <h4>乘客信息</h4>
            <el-col :span="12">
              <el-form-item label="预留人：">
                <el-input
                  placeholder="请填写预留人姓名"
                  v-model="reserve.reservedTicketPassenger"
                  maxlength="10"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="预留手机号：" prop="reservedTicketPhone">
                <el-input
                  placeholder="请填写预留手机号"
                  v-model="reserve.reservedTicketPhone"
                  maxlength="13"
                  @blur="setPassword"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="预留密码：" prop="reservedTicketPassword">
                <el-input
                  v-model="reserve.reservedTicketPassword"
                  placeholder="请填写预留密码"
                  disabled
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="备注：">
                <el-input
                  v-model="reserve.remark"
                  type="textarea"
                  rows="4"
                  maxlength="30"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="footer">
        <div
          v-if="
            popupNum == 2 || popupNum == 4 || popupNum == 5 || popupNum == 6
          "
        >
          <el-button
            v-show="!disabled"
            type="primary"
            @click="defineFun(popupNum)"
            >保存</el-button
          >
          <el-button @click="closeFun()">取消</el-button>
        </div>
        <div v-if="popupNum == 3">
          <el-button @click="closeFun()">取消</el-button>
          <el-button
            type="primary"
            :loading="loading"
            @click="generateNowFun(1)"
            >生成并覆盖</el-button
          ><el-button
            type="primary"
            :loading="loading"
            @click="generateNowFun(0)"
            >生成排班</el-button
          >
        </div>
      </div>
    </div>
    <el-dialog
      v-if="mapDialog"
      :title="areaType ? '选择下车点' : '选择上车点'"
      :visible="mapDialog"
      width="1000px"
      :close-on-click-modal="false"
      :modal="false"
      @close="cancelBtn"
    >
      <div class="map">
        <div v-if="areaMode == 0" class="searchbox">
          <header>
            <input v-model="searchInput" type="text" placeholder="搜索地址" />
            <el-button type="primary" size="small">搜索</el-button>
          </header>
          <div class="pointlist">
            <p
              v-for="(item, index) in addressList"
              :key="index"
              @click="searchClick(item)"
            >
              {{ item.address }}
            </p>
          </div>
        </div>
        <div class="select" :style="{ top: areaMode == 0 ? '55px' : '14px' }">
          <header>请选择地点</header>
          <p v-if="areaMode == 0">{{ point.address }}</p>
          <div v-if="areaMode == 1" class="point">
            <div
              v-for="(item, index) in pointList"
              :key="index"
              class="item"
              :class="{ item1: currentIndex === index }"
              @click="selectPoint(item, index)"
            >
              <span>{{ item.name }}</span>
              <span class="detial" :title="item.address">
                {{ item.address }}
              </span>
            </div>
          </div>
        </div>
        <div id="container" />
      </div>
      <span v-cloak slot="footer" class="dialog-footer">
        <el-button type="default" size="small" @click="cancelBtn"
          >取消</el-button
        >
        <el-button type="primary" size="small" @click="confirm">选择</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getAllLine,
  queryShiftList,
  manualScheduleAPI,
  configureParamsAPI,
  scheduledlineListAPI,
  AddscheduledRuleAPI,
  updateScheduledRuleAPI,
  intercityPageAPI,
  addReserveTicket,
  queryIntercityLineStationRelationPage,
  queryAreaList,
  searchPointAPI,
} from "@/api/lib/api.js";
import { checkPhone } from "@/common/utils/index.js";
import axios from "axios";
export default {
  watch: {
    carTyperadio: {
      handler(val) {
        if (val == 3) {
          this.tableSelectShow = true;
        } else {
          this.tableSelectShow = false;
        }
      },
    },
    isClearDepartureId(newVal, oldVal) {
      if (this.reserveEditable && newVal != oldVal) {
        this.reserve.shiftIntercityId = "";
      }
    },
    searchInput: {
      handler(val) {
        if (val) {
          this.searchFun(val);
        } else {
          this.addressList = [];
        }
      },
    },
  },
  props: ["popupNum", "schedulingPlanOBJ", "startStation", "endStation"],
  computed: {
    time_ticket: function () {
      return `${this.reserve.departureTime} - 余票${this.reserveSurplusTicket}`;
    },
    //出发地/到达地/出行日期变化值清空班次时间
    isClearDepartureId() {
      return (
        this.reserve.startStation +
        this.reserve.endStation +
        this.reserve.departureDate?.substr(0, 10)
      );
    },
  },
  data() {
    return {
      stationIdList: [],
      inputNumberMax: 0,
      current: new Date(),
      reserveEditable: true,
      reserveSurplusTicket: 0,
      shiftIntercityMap: null,
      reserve: {
        startStation: "",
        endStation: "",
        carryingChildrenNum: 0,
        reservedTicket: 0,
        shiftIntercityId: null,
        departureTime: null,
        stationId: "",
        startAreaId: null,
        startPointLatitude: "",
        startPointLongitude: "",
        startAddress: "",
        endAreaId: null,
        endPointLatitude: "",
        endPointLongitude: "",
        endAddress: "",
      },
      reserveRules: {
        startStation: [
          { required: true, message: "出发地不能为空", trigger: "change" },
        ],
        endStation: [
          { required: true, message: "到达地不能为空", trigger: "change" },
        ],
        departureDate: [
          { required: true, message: "出行日期不能为空", trigger: "change" },
        ],
        departureTime: [
          { required: true, message: "班次时间不能为空", trigger: "change" },
        ],
        reservedTicket: [
          { required: true, message: "预留数不能为空", trigger: "change" },
          {
            validator: (rule, value, callback) => {
              if (value > 0) return callback();
              return callback(new Error("至少预留一张票"));
            },
            trigger: "blur",
          },
        ],
        reservedTicketPhone: [
          { required: true, message: "预留电话不能为空", trigger: "change" },
          { validator: checkPhone, trigger: "blur" },
        ],
        reservedTicketPassword: [
          { required: true, message: "预留密码不能为空", trigger: "change" },
        ],
      },
      workForceTimes: [],
      Times_loading: false,
      multiple: true,
      SameCoverageChecked: false,
      takeEffectChecked: true,
      departureTime: "",
      ScheduleValue: "",
      ScheduleLineSelect: [],
      dayValue: "",
      daySelect: [],
      pickerOptions: {
        disabledDate: (time) => time.getTime() < Date.now() - 24 * 3600 * 1000,
      },
      PreSaleDays: "",
      generationTimeValue: "",
      tableData: [],
      // 指定车次的obj
      appoint: {
        value: "",
        label: "",
      },
      lineValue: "",
      tableSelectShow: false,
      tableShow: false,
      titleName: [
        {
          title: "发车时间",
          width: 100,
          SpecialJudgment: (res) => {
            let str = res.startDepartureTime;
            if (res.shiftType === 1) {
              str += "-" + res.endDepartureTime;
            }
            return str;
          },
        },
        {
          title: "开班属性",
          props: "openShiftProperties",
        },
      ],
      carTyperadio: "3",
      valueTime: "",
      textareaValue: "",
      value: "",
      // CoverageShiftValue: false,
      content1List: [
        {
          label: "线路",
          type: "select",
          value: undefined,
          Required: true,
        },
        {
          label: "车次名称",
          type: "input",
          value: undefined,
          Required: true,
        },
        {
          label: "发车日期",
          type: "date",
          value: undefined,
          Required: true,
        },
        {
          label: "发车时间",
          type: "dateTime",
          value: undefined,
          Required: true,
        },
        {
          label: "车辆等级",
          type: "select",
          value: undefined,
          Required: true,
        },
        {
          label: "票位",
          type: "input",
          value: undefined,
          Required: true,
        },
        {
          label: "备注",
          type: "textarea",
          value: undefined,
          Required: false,
        },
      ],
      lineList: [],
      carIdArray: [],
      disabled: false,
      ticketNum: 0,
      children: 0,
      showTip: false,
      areaType: null,
      mapDialog: false,
      lineId: null,
      map: null,
      marker: null,
      areaMode: null,
      searchInput: "",
      addressList: [],
      pointList: [],
      currentIndex: -1,
      areaPath: [],
      pickerPosition: null,
      positionLatLng: null,
      point: {
        address: "",
        startPointLatitude: "",
        startPointLongitude: "",
        startAddress: "",
        startAreaId: null,
        endPointLatitude: "",
        endPointLongitude: "",
        endAddress: "",
        endAreaId: null,
        stationId: null,
      },
      loading: false,
      shiftList: [
        {
          id: 1,
          label: "长期开班",
        },
        {
          id: 2,
          label: "阶段开班",
        },
        {
          id: 3,
          label: "长期停班",
        },
        {
          id: 4,
          label: "阶段停班",
        },
        {
          id: 5,
          label: "不自动开班",
        },
      ],
      shiftcheckList: [],
      singleTableDate: [], //原始数据
    };
  },
  created() {
    this.getLineList();
    if (this.popupNum == 2) {
      configureParamsAPI({ typeCode: 1 }).then((res) => {
        if (res.code == "SUCCESS") {
          this.PreSaleDays = JSON.parse(res.data.PRE_SALE_DAYS).regulationValue;
          for (let i = 0; i < this.PreSaleDays; i++) {
            this.daySelect.push({ value: i + 1, label: i + 1 });
          }
        }
      });
    }
    // 获取排班计划新增里面的线路数据
    scheduledlineListAPI().then((res) => {
      if (res.code == "SUCCESS") {
        res.data.map((item) => {
          this.ScheduleLineSelect.push({
            value: item.id,
            label: item.name,
          });
        });
      }
    });
    if (this.popupNum == 3) {
      this.shiftcheckList = [];
      //开班属性默认选中
      this.shiftList.forEach((item) => {
        this.shiftcheckList.push(item.label);
      });
    }
  },
  mounted() {
    // this.inputNumberMax = this.schedulingPlanOBJ.surplusTicket;
    // 如果是查看，禁用所有操作项
    if (!this.schedulingPlanOBJ) return;
    if (
      this.schedulingPlanOBJ.status == "query" ||
      this.schedulingPlanOBJ.status == "edit"
    ) {
      if (this.schedulingPlanOBJ.status == "query") this.disabled = true;
      this.ScheduleLineSelect.push({
        value: this.schedulingPlanOBJ.scope.lineId,
        label: this.schedulingPlanOBJ.scope.lineName,
      });
      this.multiple = false;
      this.ScheduleValue = this.schedulingPlanOBJ.scope.lineId;
      this.departureTime = this.schedulingPlanOBJ.scope.executionTime;
      this.dayValue = this.schedulingPlanOBJ.scope.executionDay;
      this.SameCoverageChecked =
        this.schedulingPlanOBJ.scope.isCover == 0 ? false : true;
      this.takeEffectChecked =
        this.schedulingPlanOBJ.scope.isEffective == 0 ? false : true;
    }
  },
  methods: {
    //查询途经点数据
    searchStationId() {
      if (this.reserve.lineId) {
        queryIntercityLineStationRelationPage({
          lineId: this.reserve.lineId,
          lineStationType: 2,
        }).then((res) => {
          if (res.code === "SUCCESS") {
            this.stationIdList = res.data.list?.filter((item) => {
              return item.lineStationStatus === 0;
            });
          }
        });
      }
    },
    //初始化预留票表单
    loadInfo(info) {
      if (!info) {
        this.reserveEditable = true;
        this.reserve.carryingChildrenNum = 0;
        this.reserve.reservedTicket = 0;
      } else {
        this.reserveEditable = false;
        this.lineId = info.lineId;
        this.setReserve(info);
      }
      this.$nextTick(() => {
        this.$refs.reserveForm.clearValidate();
      });
    },
    // 填写表单数据;
    setReserve(info) {
      this.reserveSurplusTicket = info.surplusTicket;
      this.reserve.shiftIntercityId = info.id;
      this.reserve.vehicleShiftId = info.vehicleShiftId;
      this.reserve.vehicleShiftName = info.vehicleShiftName;
      this.reserve.status = 0;
      this.reserve.lineId = info.lineId;
      this.reserve.lineName = info.lineName;
      this.reserve.departureDate = info.departureDate;
      this.reserve.departureTime = info.departureTime;
      this.reserve.companyName = info.companyName;
      this.reserve.companyId = info.companyId;
      this.reserve.startStation = info.startStation;
      this.reserve.endStation = info.endStation;
    },
    // 自动填写预留密码
    setPassword() {
      let phone = this.reserve.reservedTicketPhone;
      if (phone && phone.length >= 11) {
        this.$set(
          this.reserve,
          "reservedTicketPassword",
          phone.substr(phone.length - 4)
        );
      }
    },
    changeReservedTicket(cur, old) {
      if (cur < old && old == this.reserve.carryingChildrenNum) {
        this.showTip = true;
        this.$nextTick(() => {
          this.reserve.reservedTicket = this.reserve.carryingChildrenNum;
        });
      }
      if (cur > old) this.showTip = false;
      if (this.reserve.carryingChildrenNum < this.reserve.reservedTicket)
        this.showTip = false;
    },
    // 手动选择班次 - 配置表单信息
    selectShift(id) {
      const shiftIntercity = this.shiftIntercityMap.get(id);
      this.ticketNum = shiftIntercity.ticketNum;
      this.children = shiftIntercity.surplusCarryingChildrenNum;
      this.$set(this.reserve, "reservedTicket", 0);
      this.reserve.carryingChildrenNum = 0;
      if (shiftIntercity) {
        this.reserveSurplusTicket = shiftIntercity.surplusTicket;
        this.lineId = shiftIntercity.lineId;
        this.setReserve(shiftIntercity);
      }
      this.reserve.stationId = null;
      this.reserve.startAreaId = null;
      this.reserve.startPointLatitude = "";
      this.reserve.startPointLongitude = "";
      this.reserve.startAddress = "";
      this.reserve.endAreaId = null;
      this.reserve.endPointLatitude = "";
      this.reserve.endPointLongitude = "";
      this.reserve.endAddress = "";
    },
    computedDepartureTime(item) {
      let timeShowValue = item.startDepartureTime;
      if (item.shiftType === 1) {
        timeShowValue = item.startDepartureTime + "-" + item.endDepartureTime;
      }
      return timeShowValue;
    },
    // 查询
    searchWorkForceTimes() {
      this.Times_loading = true;
      if (
        this.reserve.startStation &&
        this.reserve.endStation &&
        this.reserve.departureDate
      ) {
        intercityPageAPI({
          startStationList: [this.reserve.startStation],
          endStationList: [this.reserve.endStation],
          startTime: this.reserve.departureDate.split(" ")[0] + " 00:00:00",
          endTime: this.reserve.departureDate.split(" ")[0] + " 23:59:59",
          currentPage: 1,
          pageSize: 1000,
        })
          .then((res) => {
            this.Times_loading = false;
            if ((res.code = "SUCCESS")) {
              this.workForceTimes = res.data.list.filter((arg) => {
                let departureTime = this.computedDepartureTime(arg);
                arg.label = departureTime + " - 余票" + arg.surplusTicket;
                // return this.reservedAble(arg);
                return arg;
              });
              setTimeout(() => {
                this.setShiftIntercityMap(this.workForceTimes);
              }, 0);
            }
          })
          .catch((e) => {
            this.Times_loading = false;
          });
      } else {
        setTimeout(() => {
          this.Times_loading = false;
        }, 1000);
      }
    },
    // 判断是否可预留
    reservedAble(row) {
      if (row.status == 2) return false;
      if (row.surplusTicket == 0) return false;
      if (row.type == 3) return false;
      const deprat =
        row.departureDate.split(" ")[0] + " " + row.departureTime + ":00";
      if (this.current > new Date(deprat)) return false;
      return true;
    },
    // 创建班线map对象，方便表单配置
    setShiftIntercityMap(arr) {
      this.shiftIntercityMap = new Map();
      arr.forEach((item) => {
        this.shiftIntercityMap.set(item.id, item);
      });
    },
    // 表格check变化事件
    handleSelectionChange(obj) {
      let labelArray = [];
      let singleArr = [];
      // obj.map((item) => {
      //   if(!this.carIdArray.some(i=>i == item.id)){
      //     this.carIdArray.push(item.id);
      //     labelArray.push(item.name);
      //   }
      // });
      obj.map(item=>{
        singleArr.push(item.id);
        labelArray.push(item.name);
      })
      this.carIdArray = singleArr;
      this.appoint.label = this.textCallback(labelArray);
    },
    textCallback(str) {
      let texts = "";
      str.map((item, index) => {
        if (index + 1 == str.length) {
          texts += item;
        } else {
          texts += item + ",";
        }
      });
      return texts;
    },
    tableSelectFun() {
      if (this.tableData.length > 0) {
        this.tableShow = true;
      } else {
        if (this.lineValue == "") {
          this.$message.error("请选择线路！");
          this.tableShow = false;
        } else {
          this.$message.error("该线路下没有班次！");
          this.tableShow = false;
        }
      }
    },

    // 线路下拉框change事件
    lineChange(val) {
      if (!val) {
        this.tableData = [];
        return;
      }
      this.generationTimeValue = "";
      let channelNo = this.lineList.find((item) => item.id === val)?.channelNo;
      console.log("val", channelNo);
      // 获取预售天数
      configureParamsAPI({
        typeCode: 1,
        lineId: val,
        channelNO: channelNo,
      }).then((res) => {
        if (res.code == "SUCCESS") {
          this.PreSaleDays = JSON.parse(res.data.PRE_SALE_DAYS).regulationValue;
          this.pickerOptions = {
            disabledDate: (time) => {
              const month = this.PreSaleDays * 24 * 3600 * 1000;
              return (
                time.getTime() < Date.now() - 24 * 3600 * 1000 ||
                time.getTime() > new Date().getTime() + month
              );
            },
          };
          for (let i = 0; i < this.PreSaleDays; i++) {
            this.daySelect.push({ value: i + 1, label: i + 1 });
          }
        }
      });
      queryShiftList({ lineId: val, status: 0 }).then((res) => {
        if (res.code == "SUCCESS") {
          /**
           * 需要前端过滤掉开班属性条件 this.shiftcheckList
           * 需要缓存一份原始数据 singleTableDate
           */
          this.singleTableDate = res.data;
          this.$nextTick(() => {
            this.checkedChange(this.shiftcheckList);
          });
        }
      });
    },
    checkedChange(val) {
      let list = [];
      this.singleTableDate.forEach((item) => {
        //只要选中的开班属性有一项满足openShiftProperties，则push
        val.forEach((e) => {
          item.openShiftProperties?.includes(e) && list.push(item);
        });
      });
      this.tableData = list;
    },
    radioFun() {
      if (this.carTyperadio == "3") {
        if (this.appoint.label) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    // 生成排版的立即生成按钮点击
    generateNowFun(CoverageShiftValue) {
      if (this.lineValue && this.generationTimeValue && this.appoint.label) {
        let objs = {
          lineId: this.lineValue,
          startDate: this.GMTToStr(this.generationTimeValue[0]),
          endDate: this.GMTToStr(this.generationTimeValue[1]),
          // vehicleShiftType: this.carTyperadio == "3" ? "" : this.carTyperadio,
          // vehicleShiftIds: this.carTyperadio == "3" ? this.carIdArray : "",
          vehicleShiftType: "",
          vehicleShiftIds: this.carIdArray,
          isCover: CoverageShiftValue,
        };
        this.loading = true;
        manualScheduleAPI(objs).then((res) => {
          if (res.code == "SUCCESS") {
            this.$message.success("生成成功！");
            this.closeFun(3);
          } else {
            this.$message.error(res.msg);
          }
          this.loading = false;
        });
      } else {
        if (!this.lineValue) {
          this.$message.error("请选择线路");
        } else {
          if (!this.generationTimeValue) {
            this.$message.error("请指定日期");
          } else {
            if (!this.appoint.label) this.$message.error("请指定车次");
          }
        }
      }
    },
    // 获取线路
    getLineList() {
      getAllLine().then((res) => {
        if (res.code === "SUCCESS") {
          this.lineList = res.data;
        }
      });
    },
    boxClickFun(e) {
      if (
        e.path &&
        (e.path[0]?.className == "cell el-tooltip" ||
          e.path[0]?.className == "cell" ||
          e.path[1]?.className == "cell el-tooltip" ||
          e.path[1]?.className == "el-table__body" ||
          e.path[1]?.className == "el-table__row")
      ) {
        this.tableShow = true;
      } else {
        if (
          e.target.className == "tableSelect-box" ||
          e.target.className == "box-spanText"
        ) {
          this.tableSelectFun();
        } else {
          this.tableShow = false;
        }
      }
    },
    // 点击保存按钮的时候
    defineFun(num) {
      let objs = {};
      // 如果是改班
      if (num == 4) {
        objs.num = num;
        objs.remark = this.textareaValue;
        objs.departureTime = this.valueTime;
      } else if (num == 5) {
        objs.num = num;
        objs.remark = this.textareaValue;
      } else if (num == 2) {
        // 如果是编辑进来的
        if (this.schedulingPlanOBJ.status == "edit") {
          updateScheduledRuleAPI({
            id: this.schedulingPlanOBJ.scope.id,
            lineId: this.ScheduleValue,
            executionTime: this.departureTime,
            executionDay: this.dayValue,
            isEffective: this.takeEffectChecked == true ? 1 : 0,
            isCover: this.SameCoverageChecked == true ? 1 : 0,
          }).then((res) => {
            if (res.code == "SUCCESS") {
              this.$message.success("修改成功！");
              this.closeFun(2);
            }
          });
        } else {
          // 否则就是新增
          let ary = [];
          this.ScheduleValue.map((item) => {
            ary.push(parseInt(item));
          });
          if (this.rulesFun()) {
            AddscheduledRuleAPI({
              lineIdList: this.ScheduleValue,
              executionTime: this.departureTime,
              executionDay: this.dayValue,
              isEffective: this.takeEffectChecked == true ? 1 : 0,
              isCover: this.SameCoverageChecked == true ? 1 : 0,
            }).then((res) => {
              if (res.code == "SUCCESS") {
                this.$message.success("新增成功！");
                this.closeFun(2);
              }
            });
          }
        }
      } else if (num == 6) {
        this.$refs.reserveForm?.validate((valid) => {
          if (valid) {
            console.log("this", this.reserve);
            // this.reserve.departureTime =
            //   this.reserve.departureTime.split("-")[0];
            this.reserve.departureDateTime = `${
              this.reserve.departureDate.split(" ")[0]
            } 22:22:00`;
            this.reserve.stationId = this.reserve.stationId || null;
            this.reserve.ticketNum = this.ticketNum;
            addReserveTicket(this.reserve).then((res) => {
              this.$message.success("预留成功");
              this.$emit("saveOk");
            });
          }
        });
      }
      this.$emit("defineFun", objs);
    },
    rulesFun() {
      if (this.ScheduleValue.length != 0) {
        if (this.departureTime) {
          if (this.dayValue) {
            return true;
          } else {
            this.$message.error("请设置生成天数");
            return false;
          }
        } else {
          this.$message.error("请选择发车时间");
          return false;
        }
      } else {
        this.$message.error("请设置线路");
        return false;
      }
    },
    closeFun(num) {
      this.$emit("closePopupFun", false, num);
    },
    // 地点选择
    selectAddress(type) {
      this.areaType = type;
      if (this.reserve.shiftIntercityId) {
        this.mapDialog = true;
        this.$nextTick(() => {
          this.loadmap(type);
        });
      } else {
        this.$message.warning("请先选择班次时间");
      }
    },
    // 实例化地图
    loadmap(type) {
      this.map = new AMap.Map("container", {
        mapStyle: "amap://styles/3046c9ba72bbfebe766c8139f83b33c8",
        zoom: 14,
        resizeEnable: true,
        expandZoomRange: true,
        showIndoorMap: false,
      });
      this.marker = null;
      const area = {
        lineId: this.lineId,
        areaType: type,
        shiftIntercityId: this.reserve.shiftIntercityId,
      };
      queryAreaList(area).then((res) => {
        if (res.code == "SUCCESS") {
          const data = res.data;
          data.forEach((item) => {
            let arr = JSON.parse(item.areaContent);
            if (item.areaMode == 0) {
              this.areaMode = 0;
              let path = [];
              arr.forEach((e) => {
                path.push([e.lng, e.lat, item.id]);
                this.areaPath.push(item);
              });
              //初始化polygon
              var polygon = new AMap.Polygon({
                path: path,
                strokeColor: "#FF33FF",
                strokeWeight: 6,
                strokeOpacity: 0.2,
                fillOpacity: 0.4,
                fillColor: "#1791fc",
                zIndex: 50,
              });
              this.map.add(polygon);
            } else {
              this.areaMode = 1;
              let obj = arr[0];
              let flag = new AMap.Marker({
                position: [obj.lng, obj.lat],
              });
              let markerTxt = new AMap.Text({
                text: item.name + "——" + item.detialAddress,
                zIndex: 777,
                anchor: "center",
                offset: new AMap.Pixel(0, -60),
                style: {
                  "box-shadow": "0px 2px 8px 0px rgba(199, 200, 201, 0.5)",
                  padding: "14px",
                },
                position: [obj.lng, obj.lat],
              });
              markerTxt.setMap(this.map);
              this.map.add(flag);
              this.pointList.push({
                name: item.name,
                address: item.detialAddress,
                lng: obj.lng,
                lat: obj.lat,
                type: area.areaType,
                areaType: item.areaType,
                id: item.id,
                stationId: item.stationId,
              });
            }
            this.map.setFitView();
          });
          if (!this.areaMode) this.positionPicker(area.areaType);
        }
      });
    },
    positionPicker(type) {
      let vm = this;
      this.map.on("drag", function () {
        vm.marker && vm.marker.hide();
      });
      this.map.on("dragend", function () {
        vm.marker && vm.marker.show();
      });
      AMapUI.loadUI(["misc/PositionPicker"], function (PositionPicker) {
        if (vm.pickerPosition) {
          vm.pickerPosition = null;
        }
        vm.pickerPosition = new PositionPicker({
          mode: "dragMap",
          map: vm.map,
          iconStyle: {
            url: require("@/assets/images/home/from_icon2.png"),
            size: [30, 39],
            ancher: [15, 30], //锚点的位置，即被size缩放之后，图片的什么位置作为选中的位置
          },
          zIndex: 666,
        });
        vm.pickerPosition.on("success", function (positionResult) {
          const pos = positionResult.position;
          if (type == 0) {
            vm.point.startPointLatitude = positionResult.position.lat;
            vm.point.startPointLongitude = positionResult.position.lng;
            new Promise((resolve, reject) => {
              //成功的时候调用resolve
              resolve(vm.regeoFun(positionResult));
              //失败的时候调用reject
              reject("error message");
            }).then((data) => {
              //处理成功后的逻辑
              vm.point.startAddress = data;
            });
          } else {
            vm.point.endPointLatitude = positionResult.position.lat;
            vm.point.endPointLongitude = positionResult.position.lng;
            new Promise((resolve, reject) => {
              //成功的时候调用resolve
              resolve(vm.regeoFun(positionResult));
              //失败的时候调用reject
              reject("error message");
            }).then((data) => {
              //处理成功后的逻辑
              vm.point.endAddress = data;
            });
          }
          vm.point.address = positionResult.address;
          vm.positionLatLng = [
            positionResult.position.lng,
            positionResult.position.lat,
          ];
          if (!vm.marker) {
            vm.marker = new AMap.Text({
              text: positionResult.address,
              zIndex: 777,
              anchor: "center",
              offset: new AMap.Pixel(0, -60),
              style: {
                "box-shadow": "0px 2px 8px 0px rgba(199, 200, 201, 0.5)",
                padding: "14px",
              },
              position: vm.positionLatLng,
            });
            vm.marker.setMap(vm.map);
          } else {
            vm.marker.setPosition(vm.positionLatLng);
            vm.marker.setText(positionResult.address);
          }
        });
        vm.pickerPosition.start();
        vm.map.panBy(0, 1);
      });
    },
    async regeoFun(positionResult) {
      const res = await axios
        .get(
          `https://restapi.amap.com/v3/geocode/regeo?output=json&location=${positionResult.position.lng},${positionResult.position.lat}&key=d85b28ec7a166619a217cfe7cc0d700b&radius=1000&extensions=all`
        )
        .then((res) => {
          const addressComponent = res.data.regeocode.addressComponent;
          const abbreviation =
            res.data.regeocode.aois[0]?.name || addressComponent.district; //简称
          const detailedAddress =
            addressComponent.province +
            addressComponent.city +
            addressComponent.district +
            addressComponent.streetNumber.street +
            addressComponent.streetNumber.number; //详细地址
          return abbreviation + "|" + detailedAddress;
        });
      return res;
    },
    // 点击搜索的地址
    searchClick(item) {
      this.positionLatLng = [item.wgLon, item.wgLat];
      this.map.setZoom(16);
      this.map.setCenter(this.positionLatLng);
      this.addressList = [];
      this.searchInput = "";
      this.point.address = item.address;
    },
    // 输入地名搜索地址
    searchFun(val) {
      const obj = {
        searchKey: val,
        lineId: this.lineId,
        areaType: this.areaType,
        shiftId: this.reserve.shiftIntercityId,
      };
      searchPointAPI(obj).then((res) => {
        this.addressList = res.data;
      });
    },
    // 选择固定点
    selectPoint(item, index) {
      this.currentIndex = index;
      this.positionLatLng = [item.lng, item.lat];
      this.map.setCenter(this.positionLatLng);
      if (item.type == 0) {
        this.point.startPointLatitude = item.lat;
        this.point.startPointLongitude = item.lng;
        this.point.startAddress = item.name + "|" + item.address;
        this.point.startAreaId = item.id;
      } else {
        this.point.endPointLatitude = item.lat;
        this.point.endPointLongitude = item.lng;
        this.point.endAddress = item.name + "|" + item.address;
        this.point.endAreaId = item.id;
        if (item.areaType == 2) {
          this.point.stationId = item.stationId;
        } else {
          this.point.stationId = null;
        }
      }
    },
    // 确认上车点下车点
    confirm() {
      if (this.areaMode == 0) {
        let inRing = this.areaPath.findIndex((item) => {
          const arr = JSON.parse(item.areaContent);
          let path = [];
          arr.forEach((e) => path.push([e.lng, e.lat]));
          return AMap.GeometryUtil.isPointInRing(this.positionLatLng, path);
        });
        if (inRing > -1) {
          if (this.areaType === 0) {
            this.reserve.startPointLatitude = this.point.startPointLatitude;
            this.reserve.startPointLongitude = this.point.startPointLongitude;
            this.reserve.startAddress = this.point.startAddress;
            this.reserve.startAreaId = this.areaPath[inRing].id;
          } else {
            this.reserve.endPointLatitude = this.point.endPointLatitude;
            this.reserve.endPointLongitude = this.point.endPointLongitude;
            this.reserve.endAddress = this.point.endAddress;
            this.reserve.endAreaId = this.areaPath[inRing].id;
            if (this.areaPath[inRing].areaType == 2) {
              this.reserve.stationId = this.areaPath[inRing].stationId;
            } else {
              this.reserve.stationId = null;
            }
          }
          this.cancelBtn();
        } else {
          this.marker && this.marker.hide();
          this.$message.warning("该地点不在可选区域内！");
        }
      } else {
        if (this.positionLatLng) {
          if (this.areaType == 0) {
            this.reserve.startPointLatitude = this.point.startPointLatitude;
            this.reserve.startPointLongitude = this.point.startPointLongitude;
            this.reserve.startAddress = this.point.startAddress;
            this.reserve.startAreaId = this.point.startAreaId;
          } else {
            this.reserve.endPointLatitude = this.point.endPointLatitude;
            this.reserve.endPointLongitude = this.point.endPointLongitude;
            this.reserve.endAddress = this.point.endAddress;
            this.reserve.endAreaId = this.point.endAreaId;
            this.reserve.stationId = this.point.stationId;
          }
          this.cancelBtn();
        } else {
          this.$message.warning("请选择固定点！");
        }
      }
      this.marker = null;
    },
    cancelBtn() {
      this.mapDialog = false;
      this.marker = null;
      this.areaPath = [];
      this.addressList = [];
      this.searchInput = "";
      this.positionLatLng = null;
      this.pointList = [];
      this.currentIndex = -1;
      if (this.map) this.map.destroy();
    },
  },
};
</script>

<style lang="scss" scoped>
.Table {
  width: 600px;
}
.reserveForm {
  .el-select {
    width: 200px;
  }
  i {
    color: #336ffe;
    margin-left: 10px;
  }
}
.tableSelect-box {
  border: 1px solid #dcdfe6;
  width: 180px;
  height: 34px;
  border-radius: 4px;
  cursor: pointer;
  span.box-spanText {
    line-height: 34px;
    width: 152px;
    overflow: hidden;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0 4px;
  }
  i {
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translateY(-50%);
    color: #dcdfe6;
  }
}
.sign {
  color: red;
  margin-top: -22px;
  margin-left: 30px;
  margin-bottom: 6px;
}
.radio-box {
  position: relative;
  margin-top: 9px;
}
/deep/ .el-range-separator {
  line-height: 24px;
}
.el-select,
.el-input,
.el-textarea,
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 300px;
}
/deep/ .el-textarea textarea {
  resize: none !important;
  height: 100px;
}
.header {
  padding: 18px 24px;
  height: 24px;
  position: absolute;
  left: 0;
  width: calc(100% - 48px);
  top: 0;
  border-bottom: 1px solid #d7d7d7;
  .text {
    margin-top: 8px;
  }
  .close {
    cursor: pointer;
    width: 12px;
    height: 12px;
    background: url("../../../../assets/images/home/close.png") no-repeat;
    background-size: 100%;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
  }
}
/deep/.el-table__empty-text {
  width: 320px !important;
  height: 300px !important;
}
.footer {
  padding: 10px 24px 18px 24px;
  height: 32px;
  position: absolute;
  left: 0;
  width: calc(100% - 48px);
  border-bottom: none !important;
  border-top: 1px solid #d7d7d7;
  bottom: 0 !important;
  text-align: right;
  .el-button {
    margin-left: 14px;
  }
}
.content-2 {
  /deep/.el-date-editor {
    width: 150px !important;
    margin-right: 10px;
  }
}

.content-3 {
  /deep/.el-date-editor {
    width: 300px !important;
    margin-right: 10px;
  }
}
.content-6 {
  .el-radio {
    margin-top: 10px;
  }
  /deep/.el-input-number__decrease,
  /deep/.el-input-number__increase {
    height: 30px;
    top: 4px;
    line-height: 30px;
  }
}
.workforcePopup {
  z-index: 777;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  .manual-content {
    width: 600px;
    background: #ffffff;
    border-radius: 6px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    .content-1,
    .content-2,
    .content-3,
    .content-4,
    .content-5,
    .content-6 {
      position: absolute;
      top: 60px;
      left: 0;
      width: 100%;
      .el-form {
        .el-input {
          width: 200px;
        }
        .el-textarea {
          width: 100%;
        }
      }
      .row {
        width: 100%;
        height: 32px;
        margin: 10px 0;
        display: flex;
        .row-left {
          width: 126px;
          height: 32px;
          text-align: right;
          line-height: 32px;
          padding-right: 14px;
          span {
            color: red;
          }
        }
        .row-right {
          width: calc(100% - 140px);
          line-height: 32px;
          span {
            display: inline-block;
            margin-right: 10px;
          }

          .content-2-input {
            margin-right: 10px;
            width: 80px;
          }
        }
      }
    }
  }
}
.map {
  position: relative;
  height: 500px;
  .searchbox {
    position: absolute;
    top: 14px;
    left: 10px;
    width: 300px;
    z-index: 1200;
    input {
      width: 212px;
      height: 28px;
      border: #d6d6d6 solid 1px;
      flex: 1;
      outline: none;
      margin-right: 16px;
      padding-left: 10px;
    }
    .pointlist {
      max-height: 250px;
      background-color: #fff;
      overflow: auto;
      margin-top: 10px;
      p {
        padding: 7px 10px;
        cursor: pointer;
        &:hover {
          background-color: #e4e4e4;
        }
      }
    }
  }
  .select {
    width: 312px;
    position: absolute;
    z-index: 1100;
    left: 10px;
    background-color: #fff;
    border-radius: 5px;
    header {
      font-size: 15px;
      color: black;
      padding: 10px 10px 0;
    }
    p {
      margin-top: 10px;
      padding: 0 10px 10px;
    }
    .point {
      margin: 10px 0;
      .item {
        border-radius: 5px;
        cursor: pointer;
        padding: 10px 10px;
        overflow: hidden; //超出的文本隐藏
        text-overflow: ellipsis; //溢出用省略号显示
        white-space: nowrap; //溢出不换行
        .detial {
          margin-left: 15px;
          font-size: 12px;
        }
      }
      .item1 {
        color: white;
        background-color: #336ffe;
      }
    }
  }
  #container {
    height: 500px;
  }
}
</style>
